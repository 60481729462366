/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var winW = $(window).outerWidth();
        // var mobileWidth = 768;


        // Navigation

        var scrollPosition = $(window).scrollTop();
        console.log(scrollPosition);

        //-- Do the menu
        $('.main-menu').on( 'click', function(){
          $('.nav-display').addClass('open');
          $('.menu-banner').addClass('open');
          $('header.banner').addClass('open');
          $('body').css({ overflowY: 'hidden' });
        });
        $('.close-menu').on( 'click', function(){
          $('.nav-display').removeClass('open');
          $('.menu-banner').removeClass('open');
          $('header.banner').removeClass('open');
          $('body').css({ overflowY: 'auto' });
        });


        // Layout updates
        $('.wp-block-columns .wp-block-image').addClass('square-image');


        // Scroll to top
        //-- scroll
        $('.scrollToTop').css('display', 'none');
        $(window).on( 'scroll', function(e) {
          e.preventDefault();
          if ($(this).scrollTop() > 100) {
            $('.scrollToTop').fadeIn();
          } else {
            $('.scrollToTop').fadeOut();
          }
        });
        //-- click
        $('.scrollToTop').on( 'click', function(){
          $("html, body").animate({ scrollTop: 0 }, 100);
        });

        // Scroll function for accordion on mobile
        //-- Bring content into view when accordion header is clicked
        $('.collapse').on('shown.bs.collapse', function(e) {
          var $card = $(this).closest('div.col-12');
          $('html,body').animate({
            scrollTop: $card.offset().top - 200
          }, 500);
        });


        // Images
        function media_text_image() {
          $('.wp-block-media-text__media').each(function(){
            var imageW = $('.wp-block-media-text__media').width();
            if(winW > 552 && winW <= 768) {
              $('.wp-block-media-text__media img').css({ height : imageW - 250 });
             } else if(winW <= 552) {
                $('.wp-block-media-text__media img').css({ height : imageW - 100 });
            } else {
              $('.wp-block-media-text__media img').css({ height : imageW });
            }
          });
        }
        media_text_image();

        function square_image() {
          $('.square-image').each(function(){
            var imageW = $('.square-image').width();
            $('.square-image img').css({ height : imageW });
          });
        }
        square_image();


        // Refresh values on resize or rotation
        var _refresh = function () {

          media_text_image();

          square_image();

        };

        $(window).on('resize', _refresh);
        $(document).on('ready', _refresh);
        $(window).on('load', _refresh);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $(window).on('load', function() {
          var scrollPosition = $(window).scrollTop();
          // console.log(scrollPosition);
          if(scrollPosition === 0) {
            $('header').addClass('large');
          }
        });

        // HEADER SCROLL
        $(window).on( 'scroll', function(e) {
          e.preventDefault();

          // Main header
          if ($(window).scrollTop() > 100) {
            $('header').removeClass('large');
          } else if ($(window).scrollTop() < 200) {
            $('header').addClass('large');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'the_teams': {
      init: function() {

        $("a[href*='#']:not([href='#'])").on('click', function(e) {

          var hash = this.hash;
          var section = jQuery(hash);

          console.log(hash);

            if($(hash).length > 0){
              e.preventDefault();

              if (hash) {

                  $('html, body').animate({
                    scrollTop: section.offset().top - 200
                  }, 800, 'swing', function(){
                    history.replaceState({}, "", hash);
                  });

              } else {

                $('html, body').animate({
                  scrollTop: section.offset().top - 200
                }, 800);

              }

          }
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
